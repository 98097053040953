import React, { useEffect, useRef, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import MDEditor from '@uiw/react-md-editor';
import axios from 'axios';

import './headerBar.css';
import {
    CBadge,
    CButton,
    CCard,
    CCardBody,
    CCardHeader,
    CCloseButton,
    CCol,
    CContainer,
    CHeader,
    CHeaderBrand,
    CHeaderDivider,
    CHeaderNav,
    CImage,
    CModal,
    CModalBody,
    CModalFooter,
    CModalHeader,
    CModalTitle,
    CNavbar,
    CNavbarBrand,
    CNavbarNav,
    CNavItem,
    CNavLink,
    COffcanvas,
    COffcanvasBody,
    COffcanvasHeader,
    COffcanvasTitle,
    CRow,
    CFormSwitch
} from "@coreui/react-pro";
import { setNotificationHeader, setNotificationMessage, setNotificationType } from "../config/Redux/actions";
import { connect } from "react-redux";
import { cilBell, cilReload, cilX, cilChatBubble, cilPhone } from "@coreui/icons";
import CIcon from "@coreui/icons-react";
import { push_notifications, wallboard_api } from "../config/config";
import notification from "../notification/Notification";

function HeaderBar(props: {
    agent: {};
    setNotificationMessage: (arg0: string) => void;
    setNotificationType: (arg0: string) => void;
    setNotificationHeader: (arg0: string) => void;
}) {
    const [messages, setMessages] = useState({ today: [], yesterday: [], older: [] });
    const [visible, setVisible] = useState(false);
    const [messageVisble, setMessageVisible] = useState(false);
    const [unreadCount, setUnreadCount] = useState(0);
    const [activeMessage, setActiveMessage] = useState({});
    const websocket = useRef(null);
    const [switchState, setSwitchState] = useState(null);
    const token = localStorage.getItem('authToken');

    // Use axios for API call to set the switch mode on component mount.
    useEffect(() => {
        console.log("useEffect triggered. props.agent.id:", props.agent?.id);

        if (props.agent && props.agent.id) {
            console.log("Fetching routing profile for user id:", props.agent.id);

            axios.get(`${wallboard_api}/routingprofile?userid=${props.agent.id}`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                }
            })
                .then(response => {
                    console.log("API Response Data:", response.data);
                    if (response.data.statusCode === 200) {
                        if (response.data.routingType === 'CHAT') {
                            setSwitchState(true);
                            console.log("Switch state updated to true");
                        } else if (response.data.routingType === 'VOICE') {
                            setSwitchState(false);
                            console.log("Switch state updated to false");
                        } else {
                            console.error("Unexpected routing type:", response.data.routingType);
                        }
                    } else {
                        console.error("Unexpected status code:", response.data.statusCode);
                    }
                })
                .catch(error => {
                    console.error("Error fetching routing profile:", error);
                });
        } else {
            console.log("props.agent.id is null or undefined. Skipping fetch.");
        }
    }, [props.agent?.id]);

    const handleSwitchChange = (e) => {
        const newState = e.target.checked;

        const channel = newState ? "chat" : "voice";
        const payload = {
            userId: `${props.agent.id}`,
            channel: channel
        };

        axios.post(`${wallboard_api}/routingprofile`, payload, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        })
            .then(response => {
                console.log(response);
                if (response.data.statusCode === 200) {
                    showNotification(`<div>Successfully switched your profile to ${channel}</div>`, `<div>Success</div>`, "Success");
                    setSwitchState(newState);
                } else {
                    showNotification(`<div>Failed to update your profile. Contact your supervisor</div>`, `<div>Error</div>`, "Error");
                    setSwitchState(!newState);
                }
                console.log('Channel updated response', response);
            })
            .catch(error => {
                console.error('Error updating channel:', error);
            });
    };

    const viewNotification = (message) =>{
        console.log(message)
        setActiveMessage(message);
        setMessageVisible(true);
        message.read = true;

        if (websocket.current) {
            websocket.current.send(JSON.stringify({
                action: 'read',
                id: `${message.id}#${props.agent.username}`
            }));
        }
        setUnreadCount(prev => prev - 1);
    }

    const deleteNotification = (messageId, category, read) => {
        if(!read){
            console.log("READ STATUS", read)
            setUnreadCount(prev => prev - 1);
        }
        setMessages(prevMessages => {
            const newMessages = {...prevMessages};
            newMessages[category] = newMessages[category].filter(msg => msg.id !== messageId);
            return newMessages;
        });

        if (websocket.current) {
            websocket.current.send(JSON.stringify({
                action: 'delete',
                id: `${messageId}#${props.agent.username}`
            }));
        }
    }

    function showNotification(message, notificationHeader, notificationType) {
        props.setNotificationMessage(message);
        props.setNotificationType(notificationType);
        props.setNotificationHeader(notificationHeader);
    }

    const formatToLocalDateTime = (isoDateString) => {
        if (isoDateString === "") {
            return "N/A";
        }
        const date = new Date(isoDateString.includes('Z') || isoDateString.includes('+') ? isoDateString : isoDateString + 'Z');
        const options = {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            hour12: false,
            timeZoneName: 'short'
        };
        return date.toLocaleString(undefined, options);
    };

    const categorizeDate = (date) => {
        const messageDate = new Date(date);
        const today = new Date();
        const yesterday = new Date(today);
        yesterday.setDate(yesterday.getDate() - 1);
        const messageDateLocal = new Date(messageDate.getTime() - messageDate.getTimezoneOffset() * 60000);
        const todayLocal = new Date(today.getTime() - today.getTimezoneOffset() * 60000);
        const yesterdayLocal = new Date(yesterday.getTime() - yesterday.getTimezoneOffset() * 60000);

        if (messageDateLocal.toDateString() === todayLocal.toDateString()) {
            return 'today';
        } else if (messageDateLocal.toDateString() === yesterdayLocal.toDateString()) {
            return 'yesterday';
        } else {
            return 'older';
        }
    };

    useEffect(() => {
        if(props.agent.id === ''){
            return;
        }

        function connect() {
            websocket.current = new WebSocket(`${push_notifications}?token=Bearer ${token}&userId=${props.agent.id}`);

            websocket.current.onopen = () => {
                console.log('WebSocket Connected');
            };

            websocket.current.onclose = event => {
                console.log('Notification WebSocket Disconnected. Attempting to reconnect...', event.reason);
                setTimeout(() => {
                    setMessages({ today: [], yesterday: [], older: [] });
                    setUnreadCount(0);
                    connect();
                }, 1000);
            };

            websocket.current.onmessage = event => {
                console.log('[WebSocket] Received raw message:', event.data);
    
                const newMessage = JSON.parse(event.data);
    
                if (newMessage.expiry) {
                    console.log('[WebSocket] Message expiry timestamp:', newMessage.expiry);
                    console.log('[WebSocket] Current timestamp:', new Date().toISOString());
                } else {
                    console.log('[WebSocket] No expiry found in message.');
                }
    
                const category = categorizeDate(newMessage.date);
                console.log("NEW MESSAGE CAT", newMessage.date, category);

                setMessages(prev => {
                    const sortedMessages = [...prev[category], newMessage].sort((a, b) => new Date(b.date) - new Date(a.date));
                    return {
                        ...prev,
                        [category]: sortedMessages
                    };
                });

                if (!newMessage.read) {
                    setUnreadCount(prev => prev + 1);
                    if (!newMessage.past) {
                        showNotification(`<div>${newMessage.message}</div>`, `<div>${newMessage.subject}</div>`, "Success");
                    }
                }
            };

            websocket.current.onerror = error => {
                console.log('WebSocket Error:', error);
                setMessages({ today: [], yesterday: [], older: [] })
                setUnreadCount(0)
            };
        }

        connect();

        const checkExpiry = () => {
            const now = new Date();
            console.log('[ExpiryCheck] Checking message expiry at:', now.toISOString());
    
            setMessages(prevMessages => {
                let unreadAdjustment = 0;
    
                const filterExpired = (msgs) =>
                    msgs.filter(msg => {
                        const expired = msg.expiry && new Date(msg.expiry) <= now;
                        if (expired && !msg.read) {
                            // Decrease unread count for expired unread messages
                            unreadAdjustment++;
                            console.log('[ExpiryCheck] Message expired and removed:', msg);
                        }
                        return !msg.expiry || new Date(msg.expiry) > now;
                    });
    
                const updatedMessages = {
                    today: filterExpired(prevMessages.today),
                    yesterday: filterExpired(prevMessages.yesterday),
                    older: filterExpired(prevMessages.older),
                };
    
                // Adjust unread count
                setUnreadCount(prevUnreadCount => Math.max(0, prevUnreadCount - unreadAdjustment));
    
                return updatedMessages;
            });
        };
    
        // Calculate delay until top of the next minute
        const now = new Date();
        const delay = (60 - now.getSeconds()) * 1000 - now.getMilliseconds();
    
        
        const timeout = setTimeout(() => {
            checkExpiry(); // Run immediately at the top of the next minute
            const interval = setInterval(checkExpiry, 60000); // Run every minute
    
            
            return () => clearInterval(interval);
        }, delay);
    
        
        return () => {
            if (websocket.current) {
                websocket.current.close();
            }
            clearTimeout(timeout);
        };
    }, [props.agent.id]);

    return (
        <>
            <CModal visible={messageVisble} onClose={() => setMessageVisible(false)} aria-labelledby="Notification" size="xl">
                <CModalHeader onClose={() => setVisible(false)}>
                    <CModalTitle id="LiveDemoExampleLabel">{activeMessage.subject}</CModalTitle>
                </CModalHeader>
                <CModalBody>
                    <MDEditor.Markdown source={activeMessage.message} />
                </CModalBody>
                <CModalFooter>
                    <small><b>From: </b>{activeMessage.publisher}</small>
                </CModalFooter>
            </CModal>
            <COffcanvas backdrop={false} placement="right" visible={visible} onHide={() => setVisible(false)}>
                <COffcanvasHeader id="offCanvasHeader">
                    <CCloseButton placement="right" className="text-reset" onClick={() => setVisible(false)} />
                </COffcanvasHeader>
                <COffcanvasBody id="offCanvasBody">
                    {['today', 'yesterday', 'older'].map((category) => (
                        messages[category].length > 0 && (
                            <CCard key={category} id="notifiactionDateCard">
                                <CCardHeader id="notificationDateCardHeader">
                                    <strong>{category.charAt(0).toUpperCase() + category.slice(1)}</strong>
                                </CCardHeader>
                                <CCardBody id="notificationDateCardBody">
                                    {messages[category].map((message, index) => (
                                        <div key={index} className={`border-start border-start-4 py-1 px-3 ${message.read ? "border-start-secondary" : "border-start-success"} border-bottom border-bottom-1 messageContainer`} onClick={()=>viewNotification(message)}>
                                            <div className="notificationHeader">
                                                <CButton color="link" variant="outline" onClick={(e) => {
                                                    e.stopPropagation(); // Prevent onClick for viewing from being triggered
                                                    deleteNotification(message.id, category, message.read);
                                                }}>
                                                    <CIcon icon={cilX}></CIcon>
                                                </CButton>
                                            </div>
                                            <div>
                                                <strong>{message.subject}</strong>
                                            </div>
                                            <div className="notificationFooter">
                                                <span>{formatToLocalDateTime(message.date)}</span>
                                            </div>
                                        </div>
                                    ))}
                                </CCardBody>
                            </CCard>
                        )
                    ))}
                </COffcanvasBody>
            </COffcanvas>

            <CHeader position="sticky" id="headerBar">
                <CContainer fluid className="d-flex justify-content-between align-items-center">
                    <CHeaderBrand>
                        <div className="scheduleIconBox">
                            <span color="white">WallBoard</span>
                        </div>
                    </CHeaderBrand>

                    <CNavbarNav id="notification" className="d-flex flex-row align-items-center gap-2">
                        {switchState !== null && (
                            <>
                                <CNavItem className="d-flex align-items-center">
                                    <CIcon icon={cilPhone} size="xl" style={{ transform: 'scaleX(-1)' }} />
                                </CNavItem>

                                <CNavItem className="d-flex align-items-center">
                                    <CFormSwitch
                                        id="customSwitch"
                                        checked={switchState}
                                        onChange={handleSwitchChange}
                                        className="custom-switch form-switch-lg mx-2"
                                    />
                                </CNavItem>

                                <CNavItem className="d-flex align-items-center me-2">
                                    <CIcon icon={cilChatBubble} size="xl" />
                                </CNavItem>
                            </>
                        )}

                        <CNavbarNav id="notification">
                            <CNavItem href="#" onClick={() => setVisible(true)} className="position-relative" disabled={(messages.today.length + messages.yesterday.length + messages.older.length) === 0}>
                                <CIcon icon={cilBell} size="lg"/>
                                {unreadCount > 0 &&
                                    <CBadge color="danger" position="top-end" shape="rounded-circle">
                                        {unreadCount} <span className="visually-hidden">unread messages</span>
                                    </CBadge>
                                }
                            </CNavItem>
                        </CNavbarNav>
                    </CNavbarNav>
                </CContainer>
            </CHeader>
        </>
    );
}

const mapDispatchToProps = {
    setNotificationMessage,
    setNotificationHeader,
    setNotificationType
};

export default connect(null, mapDispatchToProps)(HeaderBar);