import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './app/App';
import reportWebVitals from './reportWebVitals';
import store from './config/Redux/store';
import {Provider} from "react-redux";
import {PublicClientApplication} from "@azure/msal-browser";
import {msalConfig} from "./config/authConfig";
import { createRoot } from 'react-dom/client'
import {MsalProvider} from "@azure/msal-react";

const msalInstance = new PublicClientApplication(msalConfig);

const container = document.getElementById('root')
const root = createRoot(container)

root.render(
    <Provider store={store}>
        {/*<React.StrictMode>*/}
            <MsalProvider instance={msalInstance}>
                <App />
            </MsalProvider>
        {/*</React.StrictMode>*/}
    </Provider>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
