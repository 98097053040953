import React, { useEffect, useState, useRef } from 'react';
import * as RB from 'rebass';
import './homepage.css';
import CanvasJSReact from '@canvasjs/react-charts';

import HeaderBar from '../headerBar/headerBar';
import Wallboard from "../wallboard/wallboard";
import Notification from "../notification/Notification";
import { connect } from 'react-redux';

import 'amazon-connect-streams';
import { AgentClient } from "@amazon-connect/contact";
import { AmazonConnectApp } from "@amazon-connect/app";
import {CCol, CContainer, CRow} from "@coreui/react-pro";
// import { user_info } from "../config/config";
import axios from "axios";
import { setNotificationHeader, setNotificationMessage, setNotificationType } from "../config/Redux/actions";
import { useNavigate } from "react-router-dom";


function HomePage(props) {
    const [contactID, setContactID] = useState();
    const [agentID, setAgentID] = useState("");
    const [userInfo, setUserInfo] = useState({});
    const [quicksightEmbedUrl, setQuicksightEmbedUrl] = useState(""); // State to hold QuickSight embed URL
    const [embeddingContext, setEmbeddingContext] = useState(null);
    const [embeddedDashboard, setEmbeddedDashboard] = useState(null);

    const [agentInit, setAgentInit] = useState(false);
    const [agentName, setAgentName] = useState("");
    const [agentStatus, setAgentStatus] = useState("");
    const [agentUsername, setAgentUsername] = useState("");
    const [token, setToken] = useState("");
    const dashboardRef = useRef(null); // Ref for embedding QuickSight dashboard

    const navigate = useNavigate();

    function showNotification(message, notificationHeader, notificationType) {
        props.setNotificationMessage(message);
        props.setNotificationType(notificationType);
        props.setNotificationHeader(notificationHeader);
    }

    useEffect(async () => {
        // Check and initialize agent
        try {
            const { provider } = AmazonConnectApp.init({
                onCreate: async (event) => {
                    const {appInstanceId} = event.context;
                    console.log('App initialized: ', appInstanceId);
                    const agentClient = new AgentClient();
                    const agentArn = await agentClient.getARN();
                    const agentIDPart = agentArn.split("/agent/")
                    console.log("AGENT ID", agentIDPart)
                    setAgentID(agentIDPart[1])
                },
                onDestroy: (event) => {
                    console.log('App being destroyed');
                },
            });

            const msalAccountKeys = sessionStorage.getItem("msal.account.keys")
            if(msalAccountKeys){
                const user = JSON.parse(sessionStorage.getItem(JSON.parse(msalAccountKeys)[0])).username;
                setAgentUsername(user)
                console.log("USERNAME - ", user)
            }

        } catch (error) {
            console.log("No agent found", error)
        }

    }, []);


    // Dynamically scale the container based on the viewport size
    const scale = Math.min(window.innerWidth / 1920, window.innerHeight / 1080);


    return (
        <>
            <div>
                <Notification />
                <HeaderBar
                     agent={{
                         id: agentID,
                         username: agentUsername
                     }}
                /><br/>
                {/*<CContainer>*/}
                    <Wallboard/>
                {/*</CContainer>*/}
                {/*<CContainer ref={dashboardRef} />*/}
            </div>
        </>
    );
}

const mapDispatchToProps = {
    setNotificationMessage,
    setNotificationHeader,
    setNotificationType
};

export default connect(null, mapDispatchToProps)(HomePage);
